@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.itemView,
.createItem,
.login,
.logout,
.resetPassword {
  display: grid;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.admin {
  display: grid;
  height: 30vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}


header {
  position: absolute;
  top: 0;
  height: 60px;
  margin-left: 30%;
  margin-right: 30%;
  align-content: center;
}
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #00519C;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: grey;
  z-index: 9998;
}
.App {
  position: absolute;
  left: 15%;
  right: 15%;
}

.App-logo {
  position: absolute;
  top: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  max-height: 60px;
}


h1 {
  margin-left: 20px;
  color: rgb(120, 10, 10);
  font-weight: bolder;
}

p {
  margin-left: 20px;
  color: darkgrey;
}

.ucase {
  text-transform: uppercase;
}

.modalBox {
  width: 48%;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading,
.drag {
  font-weight: 600;
  color: #191442;
}

.drag {
  margin-top: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.drag .browse {
  display: inline-block;
  color: #fe8d57;
}

.file-label {
  cursor: pointer;
}

.instruction,
.info {
  color: #6f6c78;
}

.info {
  font-size: 12px;
}

.btn-container {
  display: flex;
  border: solid 0.1px;
  border-radius: 5px;
}

.uploadBox {
  padding: 2em;
  border: 2px dashed #7866e3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* First and Second Tab Styles */

.upload-icon {
  font-size: 2em;
  color: #7866e3;
}

.fileIcon {
  display: none;
}

.filename {
  font-size: 12px;
}

.warningText {
  color: red;
  font-size: 14px;
}

.Booked {
background-color: #FFFFBF;
}

.Out {
background-color: #D1FFBD;
}

.Returned {
background-color: #FFDBBB;
}

.Invoiced {
background-color: #ADD8E6;
}
